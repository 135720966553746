import axios from "../interceptors";

const DataService = {};

DataService.getData = async (
  token,
  merchantId,
  start,
  end,
  compareOption = "time"
) => {
  let url = `data?merchantId=${merchantId}&start=${start}`; // writing it like this for code readability
  if (end) {
    url += `&end=${end}`;
  }
  if (compareOption) {
    url += `&compareOption=${compareOption}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default DataService;
