import {
  FILTER_SHOPIFY_ORDERS_BY_DATE,
  SET_SHOPIFY_ORDERS_BY_DATE,
  SET_SHOPIFY_ORDERS_COMPARE_DATA,
} from "../constants/ShopifyOrders";

const initState = {
  filteredOrders: {
    totalAmount: 0,
    previousTotalAmount: 0,
    averageAmount: 0,
    ordersCount: 0,
    returningCustomers: 0,
    returningCustomersAmount: 0,
    graphData: [],
    newCustomersGraphData: [],
    returningCustomersGraphData: [],
    graphDataRefunds: [],
    refundsGraphDataMonth: [],
    totalCost: 0,
  },
  filteredOrdersPrevious: {
    totalAmount: 0,
    averageAmount: 0,
    ordersCount: 0,
    returningCustomers: 0,
    returningCustomersAmount: 0,
    graphData: [],
    graphDataRefunds: [],
  },
};

const shopifyOrders = (state = initState, action) => {
  switch (action.type) {
    case FILTER_SHOPIFY_ORDERS_BY_DATE:
      return {
        ...state,
        filteredOrders: action.payload,
      };
    case SET_SHOPIFY_ORDERS_COMPARE_DATA:
      return {
        ...state,
        compareData: action.payload,
      };
    case SET_SHOPIFY_ORDERS_BY_DATE:
      return {
        ...state,
        filteredOrders: {
          totalAmount: 0,
          previousTotalAmount: 0,
          averageAmount: 0,
          ordersCount: 0,
          returningCustomers: 0,
          returningCustomersAmount: 0,
          graphData: [],
          newCustomersGraphData: [],
          returningCustomersGraphData: [],
          graphDataRefunds: [],
          refundsGraphDataMonth: [],
          totalCost: 0,
          compareData: [],
        },
        filteredOrdersPrevious: {},
      };
    default:
      return state;
  }
};

export default shopifyOrders;
