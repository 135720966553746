import {
  SET_COMMON_PROPS,
  PAGE_COMMON_PROPS,
  SET_COMMON_PROPS_NULL,
  SET_CHANNELS,
  SET_COMMON_DATES,
  SET_COMPARE_OPTION,
} from "../constants/Common";
import { subDays } from "date-fns";

const initState = {
  datePreset: "",
  startDate: "",
  endDate: "",
  compareWith: "",
  compareOption: "time",
  page: "",
  channels: [],
};

const commonProps = (state = initState, action) => {
  switch (action.type) {
    case SET_COMMON_PROPS:
      return {
        ...state,
        datePreset: action.payload.datePreset,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        compareWith: action.payload.compareWith,
      };
    case PAGE_COMMON_PROPS:
      return {
        ...state,
        page: action.payload,
      };
    case SET_COMPARE_OPTION:
      return {
        ...state,
        compareOption: action.payload,
      };
    case SET_COMMON_DATES:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    case SET_CHANNELS:
      return {
        ...state,
        channels: action.payload.channels,
      };
    case SET_COMMON_PROPS_NULL:
      return {
        ...state,
        datePreset: "",
        startDate: subDays(new Date(), 7),
        endDate: subDays(new Date(), 1),
        compareWith: "",
        compareOption: "time",
        page: "",
      };
    default:
      return state;
  }
};

export default commonProps;
