import { useEffect, useMemo } from "react";
import { subDays } from "date-fns";
import { Space, DatePicker, Select } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import {
  setFacebookData,
  setFacebookDataPrevious,
  setShopifyOrdersByDate,
  setActiveSubscription,
  setCommonProps,
  setTargets,
  setTotalSubscriptions,
  setCommonDates,
  setCompareOption,
  setShopifyOrdersCompareData,
} from "redux/actions";
import { setGoogleData } from "redux/actions/GoogleData";
import DataService from "services/DataService";
import { useApi } from "hooks/useAPI";
import { getLocalTime } from "utils/getLocalTime";

type CompareOption = "time" | "targets";

const DateFilter = (props: any) => {
  const {
    merchant,
    selectedMerchant,
    setShopifyOrdersByDate,
    setFacebookData,
    setGoogleData,
  } = props;
  const dispatch = useDispatch();
  const { startDate, endDate, compareOption } = useSelector(
    (state: any) => state.common
  );

  // temporary fix until redux is seeded for existing users
  useEffect(() => {
    if (!startDate && !endDate) {
      dispatch(
        setCommonDates({
          startDate: subDays(new Date(), 7),
          endDate: subDays(new Date(), 1),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const { getToken } = useApi();

  const setDateRange = (dateRange: any) => {
    dispatch(setCommonDates({ ...dateRange }));
  };

  const dateRange = useMemo(() => {
    if (startDate && endDate) {
      return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      };
    }
    return {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
    };
  }, [startDate, endDate]);

  const calculateAmount = (total: any, refund: any) => {
    if (total?.length && refund?.length) {
      return total[0].totalAmount - refund[0].totalAmount;
    } else if (total?.length) {
      return total[0].totalAmount;
    }
    return 0;
  };

  const getFacebookData = (data: any) => {
    if (Object.keys(data).length) {
      const { totalSum, graphData } = data;
      return {
        spend: totalSum.length ? totalSum[0].totalSpend : 0,
        roas: totalSum.length ? totalSum[0].totalRoas : 0,
        impressions: totalSum.length ? totalSum[0].totalImpressions : 0,
        graphData,
      };
    }
    return {};
  };

  useEffect(() => {
    const fetchForUpdatedFilter = async () => {
      const token = getToken ? await getToken() : undefined;
      await onFilter(token);
    };
    fetchForUpdatedFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, selectedMerchant, compareOption]);

  const onFilter = async (token?: string) => {
    if (
      merchant.length &&
      selectedMerchant &&
      selectedMerchant.id &&
      dateRange?.startDate
    ) {
      setShopifyOrdersByDate({});
      setFacebookData({});
      setGoogleData({});

      const { startDateString, endDateString } = getLocalTime(
        dateRange.startDate,
        dateRange.endDate
      );

      const data: any = await DataService.getData(
        token,
        selectedMerchant.id,
        startDateString,
        endDateString,
        compareOption
      );
      const {
        shopify: shopifyData,
        refunds: refundsData,
        facebook: facebookData,
        google: googleData,
        cost: productCost,
        compareValues,
      } = data;
      const {
        totalSum,
        returningCustomers,
        graphData,
        newCustomersGraphData,
        returningCustomersGraphData,
        previousTotalSum,
        products,
      } = shopifyData;
      const { refundAmount, graphDataRefunds, previousRefundAmount, refunds } =
        refundsData;
      dispatch(setShopifyOrdersCompareData(compareValues));
      const filteredObject = {
        totalAmount: calculateAmount(totalSum, refundAmount),
        previousTotalAmount: calculateAmount(
          previousTotalSum,
          previousRefundAmount
        ),
        averageAmount: totalSum.length ? totalSum[0].averageAmount : 0,
        ordersCount: totalSum.length ? totalSum[0].count : 0,
        returningCustomers: returningCustomers.length
          ? returningCustomers[0].count
          : 0,
        returningCustomersAmount: returningCustomers.length
          ? returningCustomers[0].totalAmount
          : 0,
        products,
        graphData,
        newCustomersGraphData,
        returningCustomersGraphData,
        graphDataRefunds,
        refunds,
        totalCost: productCost?.totalCost?.length
          ? productCost.totalCost[0].totalCost
          : 0,
      };
      setShopifyOrdersByDate(filteredObject);
      setFacebookData(getFacebookData(facebookData));
      setGoogleData(googleData);
    }
  };

  return (
    <Space direction="horizontal" style={{ margin: 5 }}>
      <DatePicker.RangePicker
        value={[moment(dateRange.startDate), moment(dateRange.endDate)]}
        onChange={(rangesByKey) => {
          if (!rangesByKey?.[0] || !rangesByKey?.[1]) {
            return;
          }

          setDateRange({
            startDate: rangesByKey[0].toDate(),
            endDate: rangesByKey[1].toDate(),
          });
        }}
        ranges={{
          Today: [moment().startOf("day"), moment().endOf("day")],
          Yesterday: [
            moment().subtract(1, "days").startOf("day"),
            moment().subtract(1, "days").endOf("day"),
          ],
          "Last 7 Days": [
            moment().subtract(7, "days").startOf("day"),
            moment().subtract(1, "days").endOf("day"),
          ],
          "Last 30 Days": [
            moment().subtract(30, "days").startOf("day"),
            moment().subtract(1, "days").endOf("day"),
          ],
          "This Month": [
            moment().startOf("month").startOf("day"),
            moment().endOf("month").endOf("day"),
          ],
          "Last Month": [
            moment().subtract(1, "month").startOf("month").startOf("day"),
            moment().subtract(1, "month").endOf("month").endOf("day"),
          ],
          "This Year": [
            moment().startOf("year").startOf("day"),
            moment().endOf("year").endOf("day"),
          ],
          "Last Year": [
            moment().subtract(1, "year").startOf("year").startOf("day"),
            moment().subtract(1, "year").endOf("year").endOf("day"),
          ],
        }}
      />
      <Select
        value={compareOption}
        onChange={(value: CompareOption) => dispatch(setCompareOption(value))}
        style={{ width: 200 }}
        options={[
          { label: "Compare with Time", value: "time" },
          { label: "Compare with Targets", value: "targets" },
        ]}
        placeholder="Compare Option"
      />
    </Space>
  );
};

const mapStateToProps = ({
  merchants,
  shopifyOrders,
  common,
  googleData,
  auth,
}: any) => {
  const { merchant, selectedMerchant } = merchants;
  const { totalAmount, averageAmount, ordersCount } = shopifyOrders;
  const { datePreset, startDate, endDate, compareWith } = common;
  const { data: googleAdsData } = googleData;
  const { user } = auth;

  return {
    googleAdsData,
    merchant,
    selectedMerchant,
    totalAmount,
    averageAmount,
    ordersCount,
    datePreset,
    startDate,
    endDate,
    compareWith,
    user,
  };
};

const mapDispatchToProps = {
  setShopifyOrdersByDate,
  setFacebookData,
  setFacebookDataPrevious,
  setCommonProps,
  setGoogleData,
  setTotalSubscriptions,
  setActiveSubscription,
  setTargets,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
